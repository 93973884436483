import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import { keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import tracking from "../../assets/images/tracking.png";
import ai from "../../assets/images/ai.png";
import cloud from "../../assets/images/cloud.png";
import seo from "../../assets/images/seo.png";
import Element1 from "../../assets/images/Element2.png";
import Element3 from "../../assets/images/Element3.png";

// Keyframes for image slide-in and zoom effects
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Custom hook to handle intersection observer
const useOnScreen = (ref, rootMargin = "0px") => {
    const [isVisible, setIsVisible] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false); // To track if the animation has already run
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Trigger animation only if it's the first time the element is visible
          if (entry.isIntersecting && !hasAnimated) {
            setIsVisible(true);
            setHasAnimated(true);  // Prevent re-triggering the animation
          }
        },
        { rootMargin }
      );
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, rootMargin, hasAnimated]); // Include hasAnimated in dependencies
  
    return isVisible;
  };

const Servicesec2 = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  // Refs for the elements to observe
  const titleRef = useRef(null);
  const introTextRef = useRef(null);
  const serviceRef1 = useRef(null);
  const serviceRef2 = useRef(null);
  const serviceRef3 = useRef(null);
  const serviceRef4 = useRef(null);

  // State to track visibility of each service section
  const titleVisible = useOnScreen(titleRef);
  const introTextVisible = useOnScreen(introTextRef);
  const service1Visible = useOnScreen(serviceRef1);
  const service2Visible = useOnScreen(serviceRef2);
  const service3Visible = useOnScreen(serviceRef3);
  const service4Visible = useOnScreen(serviceRef4);

  // Track visibility of all service sections
  const servicesVisible = [service1Visible, service2Visible, service3Visible, service4Visible];

  const handleMoreClick = () => {
    navigate("/services"); // Redirect to the services page
  };

  const renderImage = (src, alt, index, animationDirection) => (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        height: isSmallScreen ? '250px' : '300px',
        width: isSmallScreen ? '250px' : '300px',
        borderRadius: '15px',
        animation: servicesVisible[index] ? `${animationDirection} 1s ease-out` : 'none',
        transition: 'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
          cursor: 'pointer',
        },
      }}
    />
  );

  return (
    <Box
      sx={{
        width: '100%',
        background: "#FFFFFF",
        position: "relative",
        textAlign: "center",
      }}
    >
      {/* Shaded blue areas */}
      <Box
        sx={{
          position: "absolute",
          width: "300px",
          height: "300px",
          background:
            "radial-gradient(circle, rgba(25,118,210,0.5) 0%, rgba(255,255,255,0) 70%)",
          borderRadius: "50%",
          top: "40%",
          left: "0%",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "300px",
          height: "300px",
          background:
            "radial-gradient(circle, rgba(25,118,210,0.5) 0%, rgba(255,255,255,0) 70%)",
          borderRadius: "50%",
          top: "0%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      />

      {/* Element Images */}
      <Box
        component="img"
        src={Element1}
        alt="Element 1"
        sx={{
          width: { xs: "50px", md: "150px" },
          height: "auto",
          position: "absolute",
          top: "0%",
          right: "0%",
          zIndex:0,
        }}
      />
      <Box
        component="img"
        src={Element3}
        alt="Element 3"
        sx={{
          width: { xs: "50px", md: "150px" },
          height: "auto",
          position: "absolute",
          zIndex:0,
          left: "0%",
          bottom: "20%",
        }}
      />



      {/* Service Blocks */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {[
          { title: 'Tracking Systems', desc: 'Stay ahead with our tracking systems that gives you eyes on every aspect of your business operations. Whether it’s logistics, resources, or workflows, you’ll always have real-time insights at your fingertips.', img: tracking, ref: serviceRef1 },
          { title: 'Custom AI Chatbots', desc: 'Our custom AI chatbots are designed to enhance user interaction, providing tailored solutions that automate conversations and improve engagement for businesses and individuals alike.', img: ai, ref: serviceRef2 },
          { title: 'Cloud Development', desc: 'Elevate your business with the flexibility of the cloud. Our cloud services provide a secure and scalable platform to help you fly high, ensuring smooth operations wherever your team is based.', img: cloud, ref: serviceRef3 },
          { title: 'SEO and Digital Marketing', desc: 'Shine bright in the crowded digital landscape with our SEO and digital marketing services. We help you stand out, drive targeted traffic, and connect with the customers that matter most.', img: seo, ref: serviceRef4 },
        ].map((service, index) => (
          <Box
            key={index}
            ref={service.ref}
            sx={{
              width: '80%',
              backgroundColor: '#ffffff',
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : (index % 2 === 0 ? 'row' : 'row-reverse'), // Alternate card direction
              alignItems: 'center',
              justifyContent: 'center',
              padding: isSmallScreen ? '15px' : '40px',
              marginBottom: '30px',
              borderRadius: '25px',
              zIndex:1,
              boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            {/* Image Slide-In Effect */}
            {renderImage(service.img, service.title, index, index % 2 === 0 ? slideInFromLeft : slideInFromRight)}

            {/* Text with Slide-In from Top Effect */}
            <Box
              sx={{
                paddingLeft: isSmallScreen ? '0' : (index % 2 === 0 ? '30px' : '0'),
                paddingRight: isSmallScreen ? '0' : (index % 2 === 0 ? '0' : '30px'),
                textAlign: 'justify',
                lineHeight: '1.75',
                color: '#455a64',
                zIndex:1,
                animation: servicesVisible[index] ? `${slideInFromTop} 1s ease-out` : 'none',
              }}
            >
              <Typography variant="h4" color="#0288d1" fontWeight="bold" sx={{fontSize: { xs: "1.5rem", sm: "2rem", md: "2.4rem" }, }}>
                {service.title}
              </Typography>
              <Typography sx={{color: '#546e7a', marginTop: '10px', fontStyle:'italic', fontSize: {xs:"15px", md:"19px"}, }}>
                {service.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

    </Box>
  );
};

export default Servicesec2;

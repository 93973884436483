import React from "react";
import { Box, Typography, Grid, LinearProgress } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import about from "../../assets/images/aboutsec1.png";
import Element1 from "../../assets/images/Element1.png";
import Element3 from "../../assets/images/Element3.png";

const skills = [
  { name: "MACHINE LEARNING", value: 65 },
  { name: "UI/UX", value: 85 },
  { name: "WEB DEVELOPMENT", value: 90 },
];

const Aboutsec1 = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <section
      id="about"
      style={{
        padding:"30px 20px",
        background: "#fff",
        color: "#333",
        position: "relative",
        overflow: "hidden",
      }}
    >

      <Box
        component="img"
        src={Element3}
        alt="Decorative Element"
        sx={{
          width: { xs: "50px", md: "150px" },
          height: "auto",
          position: "absolute",
          left:{xs:"4%", sm:"2%", md:"4%"},
          top: "25%",
          transform: "translate(-50%, -50%)",
          zIndex: 0,
        }}
      />

      <Typography
        variant="h1"
        gutterBottom
        sx={{
          marginBottom: "0.4rem",
          fontSize: { xs: "1.9rem", sm: "2.2rem", md: "2.8rem" },
          fontWeight: "700",
          color: "#1a73e8",
          textAlign: "center",
          letterSpacing: "0.05rem",
          zIndex: 2,
        }}
      >
        About Calebs
      </Typography>

      <Grid
        container
        spacing={4}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: { xs: "100%", md: "80%" },
          margin: "0 auto",
          position: "relative",
          zIndex: 1,
        }}
        ref={ref}
      >

        <Grid item xs={12} md={6}>
        <motion.div
  initial={{ opacity: 0, x: -50 }}
  animate={inView ? { opacity: 1, x: -12 } : {}} 
  transition={{ duration: 0.8, ease: "easeOut" }} 
>
  <Box
    sx={{
      position: "relative",
      width: "100%",
      textAlign: "center",
      marginRight:"auto",
      marginLeft:"auto"
    }}
  >
    <Box
      component="img"
      src={about}
      alt="Profile"
      sx={{
        width: "90%",
        borderRadius: "10%",
        objectFit: "cover",
        boxShadow: "0 8px 40px rgba(0, 0, 0, 0.2)",
        position: "relative",
        zIndex: 2,
        transition: "transform 0.4s ease, box-shadow 0.4s ease",
        marginRight: "auto", 
        marginLeft: "auto",
        "&:hover": {
          transform: "scale(1.1)",
          boxShadow: "0 12px 50px rgba(0, 0, 0, 0.3)",
        },
      }}
    />
  </Box>
</motion.div>

        </Grid>

        <Grid item xs={12} md={6}>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
            sx={{marginRight: "auto", marginLeft: "auto",}}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                marginBottom: "1.5rem",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.4rem" },
                fontWeight: "600",
                color: "#1a73e8",
                textAlign: "left",
                letterSpacing: "0.05rem",
              }}
            >
              Skills & Expertise
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                marginBottom: "1.5rem",
                color: "#777",
                lineHeight: "1.8",
                textAlign: "left",
                fontSize: { xs: "0.9rem", sm: "1rem" },
              }}
            >
              At Calebs, we offer a range of services to suit different levels
              of expertise, from simple tasks to more complex projects. Our team
              of freelancers is here to assist you, whether you're looking for
              basic help or more advanced support. We’re dedicated to providing
              reliable and affordable solutions tailored to your needs,
              ensuring you get the right help at the right level.
            </Typography>


            {skills.map((skill, index) => (
              <Box mb={4} key={skill.name}>
                <motion.div
                  initial={{ width: "0%" }}
                  animate={{ width: inView ? `${skill.value}%` : "0%" }}
                  transition={{ duration: 1.2, delay: index * 0.3 }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginBottom: "0.5rem" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        width: { xs: "120px", sm: "150px" },
                        textAlign: "left",
                        color: "#333",
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        fontSize: { xs: "0.7rem", sm: "1rem" },
                      }}
                    >
                      {skill.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        width: "50px",
                        textAlign: "right",
                        color: "#1a73e8",
                        fontWeight: "500",
                      }}
                    >
                      {skill.value}%
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={skill.value}
                    sx={{
                      height: "8px",
                      borderRadius: "5px",
                      backgroundColor: "#ddd",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#1a73e8",
                      },
                      boxShadow: "0 4px 10px rgba(26, 115, 232, 0.2)",
                    }}
                  />
                </motion.div>
              </Box>
            ))}
          </motion.div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Aboutsec1;

import Aboutsec1 from "../../components/AboutUs/AboutSec1";
import Aboutsec2 from "../../components/AboutUs/Aboutsec2";
import AboutSec3 from "../../components/AboutUs/AboutSec3";

const About = () => {
    return (
        <>
        <Aboutsec1/>
        <Aboutsec2/>
        <AboutSec3/>
        </>
    );
}

export default About;
import React from 'react';
import { Box, Typography } from '@mui/material';
import ringBg from '../../assets/images/Element2.png'; 

const Aboutsec2 = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: { xs: '20px', md: '40px' }, 
        position: 'relative',
        flexDirection: { xs: 'column', md: 'row' }, 
        marginBottom: '20px'
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '60%' }, 
          borderRadius: '15px',
          border: '10px solid #000000',
          position: 'relative',
          zIndex: 2,
          padding: 0,
        }}
      >
        <Box
          sx={{
            padding: { xs: '20px', md: '40px' }, 
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#407aff',
              fontFamily: 'Montserrat',
              textAlign: 'left',
              mb: 2,
              fontSize: { xs: '24px', md: '32px' },
            }}
          >
            Why Choose Us!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '16px', md: '18px' }, 
              color: '#333',
              lineHeight: '1.6',
              fontFamily: 'Poppins',
              textAlign: 'left',
            }}
          >
            At Calebs Tech, we provide cutting-edge software and technology solutions that drive growth and innovation.
            Our expert team of young, dynamic professionals is committed to delivering custom solutions that empower businesses
            to thrive in the digital age.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: '20px',
              color: '#407aff',
              fontStyle: 'italic',
              fontFamily: 'Poppins',
              fontSize: { xs: '14px', md: '16px' },
            }}
          >
            #Calebs #CalebsTech
          </Typography>
        </Box>

        <Box
          sx={{
            width: { xs: '80px', md: '120px' }, 
            height: { xs: '80px', md: '120px' }, 
            position: 'absolute',
            bottom: { xs: '5%', md: '0%' }, 
            right: { xs: '5%', md: '0%' },
            backgroundImage: `url(${ringBg})`,
            zIndex: 1, 
            opacity: 0.7, 
          }}
        />
      </Box>
    </Box>
  );
};

export default Aboutsec2;

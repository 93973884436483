import React from "react";
import {
  Box,
  Typography,
  Grid,
  Link,
  IconButton,
  Container,
} from "@mui/material";
import { Facebook, Twitter, LinkedIn, GitHub } from "@mui/icons-material";

const CalebsFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0A1929",
        color: "#FFFFFF",
        py: { xs: 5, md: 5 },
        position: "relative",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          sx={{ zIndex: 2, position: "relative" }}
          justifyContent="space-between"
        >

          <Grid item xs={12} md={4}>
            <Box textAlign={{ xs: "left", md: "left" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  textAlign: { xs: "left", md: "left" },
                }}
              >
                Calebs
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  opacity: 0.8,
                  textAlign: { xs: "left", md: "left" },
                  lineHeight: 1.8,
                }}
              >
                We connect freelancers with opportunities across the globe.
                Building careers and businesses one project at a time.
              </Typography>
            </Box>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={5} md={4}>
            <Box textAlign={{ xs: "left", md: "left" }} sx={{paddingLeft:{xs:"0px", sm:"0px", md:"160px"}}}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  textAlign: { xs: "left", md: "left" },
                }}
              >
                Quick Links
              </Typography>
              {[
                { text: "Home", link: "/" },
                { text: "About Us", link: "/about" },
                { text: "Services", link: "/services" },
                { text: "Contact", link: "/contact" },
              ].map((item, index) => (
                <Link
                  key={index}
                  href={item.link}
                  underline="none"
                  color="inherit"
                  sx={{
                    display: "block",
                    mb: 1,
                    opacity: 0.8,
                    textAlign: { xs: "left", md: "left" },
                    "&:hover": {
                      opacity: 1,
                      pl: { xs: 0, md: 1 },
                      transition: "all 0.3s ease",
                    },
                  }}
                >
                  {item.text}
                </Link>
              ))}
            </Box>
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={7} md={4}>
            <Box textAlign={{ xs: "left", md: "left" }} sx={{paddingLeft:{xs:"0px", sm:"0px", md:"110px"}}}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                  textAlign: { xs: "left", md: "left" },
                }}
              >
                Follow Us
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-start" },
                  gap: 1,
                }}
              >
                {[
                  { icon: <Facebook />, link: "https://facebook.com" },
                  { icon: <Twitter />, link: "https://twitter.com" },
                  { icon: <LinkedIn />, link: "https://linkedin.com" },
                  { icon: <GitHub />, link: "https://github.com" },
                ].map((social, index) => (
                  <IconButton
                    key={index}
                    href={social.link}
                    target="_blank"
                    sx={{
                      color: "inherit",
                      "&:hover": {
                        transform: "scale(1.2)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    {social.icon}
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom Section */}
        <Box
          sx={{
            mt: 2,
            pt: 2,
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
            textAlign: "center",
            fontSize: "0.875rem",
            opacity: 0.7,
          }}
        >
          © 2024 Calebs. All rights reserved.
        </Box>
      </Container>
    </Box>
  );
};

export default CalebsFooter;

import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import Element1 from "../../assets/images/Element2.png";

const teamMembers = [
  { name: 'Timothy Victor', role: 'UI/UX Designer(Founder)', image: '/images/victor.jpg' },
  { name: 'Anupoju Venkata Balaji', role: 'Frontend Developer(Co-Founder)', image: '/images/balaji.jpg' },
  { name: 'Joel Raj Kammara', role: 'UI/UX Designer', image: '/images/joel.jpeg' },
  { name: 'Tarun B', role: 'Backend Developer', image: '/images/tarun.jpg' },
  { name: 'Bheemarasetty Praneesha', role: 'Frontend Developer', image: '/images/praneesha.jpg' },
  { name: 'Vidya Sinha', role: 'Frontend Developer', image: '/images/vidya.jpeg' },
  { name: 'Asif Umeed Shaik', role: 'Backend Developer', image: '/images/asif.jpeg' },
  { name: 'Shazin Hijazy', role: 'Project Aquistion Manager', image: '/images/shazin.jpeg' },
];

const AboutSec3 = () => {
  return (
    <Box sx={{ position: 'relative', padding: '40px', backgroundColor: '#fff' }}>
  
      <Box
        component="img"
        src={Element1}
        alt="Element 1"
        sx={{
          width: { xs: '80px', sm: '100px', md: '150px' },
          height: 'auto',
          position: 'absolute',
          top: { xs: '5%', md: '10%' },
          right: { xs: '5%', md: '0%' },
          zIndex: 0, 
        }}
      />
      
      {/* Section Title */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ zIndex: 1, position: 'relative', fontWeight: 'bold', color: '#333' }}
      >
        Our Team Members
      </Typography>

      {/* Team Members Grid */}
      <Grid container spacing={4} justifyContent="center" sx={{ position: 'relative', zIndex: 1 }}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                textAlign: 'center',
                padding: '20px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                backgroundColor: '#fff',
                '&:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 0.3s ease-in-out',
                },
              }}
            >
              {/* Member Image */}
              <Box
                component="img"
                src={member.image}
                alt={member.name}
                sx={{
                  width: 150,
                  height: 150,
                  margin: '0 auto',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              />
              <CardContent>
                {/* Member Name */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                  {member.name}
                </Typography>
                {/* Member Role */}
                <Typography variant="body2" color="textSecondary">
                  {member.role}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AboutSec3;

import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'; 
import LoadingScreen from './components/Loading/Loading';
import Home from './pages/Home/Home';
import About from './pages/AboutUs/About';
import Contact from './pages/ContactUs/Contact';
import Project from './pages/Projects/Project';
import Services from './pages/Services/Services';
import Header from './components/Navbar/Header';
import Footer from './components/Footer/Footer';
import './App.css';

function App() {
  const [loading, setLoading] = useState(() => {
    return localStorage.getItem('hasLoaded') !== 'true';
  });

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem('hasLoaded', 'true');
      }, 2000); 
    }
  }, [loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/project" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

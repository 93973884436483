import React from 'react';
import Box from '@mui/material/Box';

const LoadingScreen = () => {
  return (
    <>
      <style>
        {`
          /* General Styling for the loading screen spans */
          .loading-text span {
            display: inline-block;
            opacity: 0;
            animation: waveEffect 1.5s ease forwards;
            transform: translateY(30px) scale(0.8);
            color: transparent;
            background-image: linear-gradient(90deg, #0a7ac9, #bfe9ff);
            background-clip: text;
            -webkit-background-clip: text;
            padding-right: 5px;
            text-shadow: 0 0 8px rgba(255, 255, 255, 0.4);  /* Add glow */
          }

          /* Individual delays for each letter */
          .loading-text span:nth-child(1) { animation-delay: 0.2s; }
          .loading-text span:nth-child(2) { animation-delay: 0.4s; }
          .loading-text span:nth-child(3) { animation-delay: 0.6s; }
          .loading-text span:nth-child(4) { animation-delay: 0.8s; }
          .loading-text span:nth-child(5) { animation-delay: 1s; }
          .loading-text span:nth-child(6) { animation-delay: 1.2s; }
          .loading-text span:nth-child(7) { animation-delay: 1.4s; }

          /* Wave Effect for Text */
          @keyframes waveEffect {
            0% {
              opacity: 0;
              transform: translateY(30px) scale(0.8);
            }
            50% {
              opacity: 1;
              transform: translateY(-10px) scale(1.1); /* Add bounce effect */
            }
            100% {
              opacity: 1;
              transform: translateY(0px) scale(1);
            }
          }

          /* Loading bar container */
          .loading-bar {
            width: 80%;
            margin-top: 40px;
            height: 6px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);  /* Subtle shadow */
          }

          /* Progress bar with pulsing effect */
          .progress {
            width: 0;
            height: 100%;
            background: linear-gradient(270deg, #0a7ac9, #bfe9ff);
            animation: loadProgress 5s ease-in-out infinite, pulse 2s infinite;
            border-radius: 10px;
          }

          /* Loading bar progress animation */
          @keyframes loadProgress {
            0% { width: 0%; }
            50% { width: 100%; }
            100% { width: 0%; }
          }

          /* Pulsing effect to give the bar a breathing animation */
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }

          /* Glowing hover effects for interactivity */
          .loading-text span:hover {
            text-shadow: 0 0 12px #ffffff; /* More intense glow on hover */
            transition: text-shadow 0.3s ease;
          }
          
          /* Background Animation (Keep subtle but dynamic) */
          body {
            background:linear-gradient(135deg, #020024, #090979, #00d4ff);
            background-size: 300% 300%;
            animation: bgAnimation 10s ease infinite;
          }

          @keyframes bgAnimation {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
        `}
      </style>

      <Box sx={loadingStyle}>
        <div className="loading-text">
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
        </div>
        <div className="loading-bar">
          <div className="progress"></div>
        </div>
      </Box>
    </>
  );
};

const loadingStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  fontSize: 'clamp(28px, 6vw, 52px)',
  background: 'linear-gradient(90deg, #020024, #090979, #00d4ff)',
  color: 'white',
  letterSpacing: '12px',
  fontFamily: '"Montserrat", sans-serif', 
};

export default LoadingScreen;

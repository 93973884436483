import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import { motion } from "framer-motion";
import project1 from "../../assets/images/about.jpg";

const Projectsec1 = () => {
  const [inView, setInView] = useState(false);

  const handleScroll = () => {
    const section = document.getElementById("portfolio");
    if (section) {
      const { top } = section.getBoundingClientRect();
      if (top < window.innerHeight * 0.9) {
        setInView(true);
        window.removeEventListener("scroll", handleScroll);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cardContainerStyle = {
    perspective: "1500px", // Perspective for the 3D effect
  };

  const projects = [
    { name: "Project 1", image: project1, description: "This is a description for Project 1." },
    { name: "Project 2", image: project1, description: "This is a description for Project 2." },
    { name: "Project 3", image: project1, description: "This is a description for Project 3." },
  ];

  // Animation variants for each card item (text and image)
  const cardVariants = {
    hidden: { opacity: 0, translateZ: -100, scale: 0.8 },
    visible: (delay) => ({
      opacity: 1,
      translateZ: 0,
      scale: 1,
      transition: {
        duration: 0.6,
        delay: delay, // Adding stagger effect for each element
      },
    }),
  };

  return (
    <section id="portfolio" style={{ padding: "60px 0", position: "relative" }}>
      <Container>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          style={{ fontWeight: "bold", color: "#0277bd" }}
        >
          Our Dynamic Projects
        </Typography>

        {/* Main Card Container */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={inView ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 0.7, delay: 0.3 }}
        >
          <Grid container spacing={4} justifyContent="center">
            {projects.map((project, index) => (
              <Grid item md={4} xs={12} key={index}>
                <motion.div
                  style={cardContainerStyle}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <Card
                    style={{
                      borderRadius: "15px",
                      padding: "20px",
                      transition: "transform 0.5s ease-out, box-shadow 0.5s ease-out",
                      boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
                      background: "#fff",
                      position: "relative",
                      transform: "rotateX(8deg) rotateY(-6deg) scale(1.02)", // Subtle 3D rotation and scale
                      perspective: "1500px", // Keeping the 3D effect
                      overflow: "visible",
                    }}
                  >
                    {/* Title */}
                    <motion.div
                      custom={0.1} // Delay for animation
                      variants={cardVariants}
                    >
                      <Typography
                        variant="h5"
                        className="card-item-text"
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          color: "#333",
                          transform: "translateZ(20px)", // Adding slight 3D effect
                          transition: "transform 0.5s ease, box-shadow 0.5s ease",
                        }}
                      >
                        {project.name}
                      </Typography>
                    </motion.div>

                    {/* Description */}
                    <motion.div
                      custom={0.2} // Staggered delay for description
                      variants={cardVariants}
                    >
                      <Typography
                        variant="body2"
                        className="card-item-text"
                        style={{
                          color: "#666",
                          marginBottom: "20px",
                          transform: "translateZ(15px)", // Adding slight 3D effect
                          transition: "transform 0.5s ease, box-shadow 0.5s ease",
                        }}
                      >
                        {project.description}
                      </Typography>
                    </motion.div>

                    {/* Project Image */}
                    <motion.div
                      custom={0.3} // Staggered delay for image
                      variants={cardVariants}
                    >
                      <CardMedia
                        component="img"
                        className="card-item"
                        image={project.image}
                        alt={project.name}
                        boxShadow="0 10px 25px rgba(0, 0, 0, 1)"
                        style={{
                          borderRadius: "10px",
                          transform: "translateZ(10px) scale(1.02)", // Slight zoom effect for 3D depth
                          transition: "transform 0.4s ease",
                          width: "100%",
                        }}
                      />
                    </motion.div>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </section>
  );
};

export default Projectsec1;

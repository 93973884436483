import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Stack,
  TextField,
  Typography,
  ThemeProvider,
  createTheme,
  styled,
  Link,
} from "@mui/material";
import { LocationOn, Phone, Email, Language } from "@mui/icons-material";

const theme = createTheme({
  typography: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' },
  palette: {
    primary: { main: "#fff" },
    background: { default: "#FFF" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: { body: { backgroundColor: "#fff" } },
    },
  },
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-underline:before": { borderBottomColor: "rgba(0,0,0,0.3)" },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#6ac5fe !important",
  },
  "& .MuiInput-underline:after": { borderBottomColor: "black" },
  "& .MuiInputBase-input": { color: "black" },
  "& .MuiInputLabel-root": { color: "rgba(0,0,0,0.6)" },
  "& .MuiInputLabel-root.Mui-focused": { color: "black" },
}));

const Contactus = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.Name) newErrors.Name = "Name is required";
    if (!formData.Email) newErrors.Email = "Email is required";
    else if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(formData.Email))
      newErrors.Email = "Invalid Email format";
    if (!formData.Phone) newErrors.Phone = "Phone Number is required";
    else if (!/^\d{10}$/.test(formData.Phone))
      newErrors.Phone = "Phone Number must be exactly 10 digits";
    if (!formData.Message) newErrors.Message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setIsSubmitting(true);
    setSubmitMessage("");

    const formBody = new URLSearchParams();
    for (const key in formData) {
      formBody.append(key, formData[key]);
    }

    fetch(
      "https://script.google.com/macros/s/AKfycbwuuzh80HuZC8mGP35QsZ34QeP_n9ZDOcMtL_jTqocS7LuTthlwrqZwunoyeK-EmtlMSw/exec",
      {
        method: "POST",
        body: formBody,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.text())
      .then((data) => {
        setIsSubmitting(false);
        if (data.includes("successfully sent")) {
          setSubmitMessage("Thank you for your Message!");
          setFormData({ Name: "", Email: "", Phone: "", Message: "" });
        } else {
          setSubmitMessage("Submission failed. Please try again.");
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setSubmitMessage("Submission failed. Please try again.");
      });
  };

  const contactDetails = [
    {
      icon: <LocationOn />,
      title: "Address:",
      content: [
        <Link
          key="address-link"
          href="https://maps.app.goo.gl/LV4Z5Y9Qmby2q4Wx7"
          target="_blank"
          underline="hover"
          color="inherit"
          sx={{ textDecoration: "none", "&:hover": { color: "#1e88e5" } }}
        >
          Andhra University, Madillapalem, Visakhapatnam, Andhra Pradesh -
          530003
        </Link>,
      ],
    },
    {
      icon: <Phone />,
      title: "Phone:",
      content: [
        <Link
          key="phone"
          href="tel:+919505551210"
          underline="hover"
          color="inherit"
          sx={{ textDecoration: "none", "&:hover": { color: "#1e88e5" } }}
        >
          +91 9505551210
        </Link>,
      ],
    },
    {
      icon: <Email />,
      title: "Email:",
      content: [
        <Link
          key="email"
          href="mailto:calebstec@gmail.com"
          underline="hover"
          color="inherit"
          sx={{ textDecoration: "none", "&:hover": { color: "#1e88e5" } }}
        >
          calebstec@gmail.com
        </Link>,
      ],
    },
    {
      icon: <Language />,
      title: "Website:",
      content: [
        <Link
          key="website"
          href="https://calebs.tech"
          target="_blank"
          underline="hover"
          color="inherit"
          sx={{ textDecoration: "none", "&:hover": { color: "#1e88e5" } }}
        >
          calebs.tech
        </Link>,
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container sx={{ display: "flex", justifyContent: "center", py: 8 }}>
        <Container
          sx={{
            width: "100%",
            maxWidth: "1200px",
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            padding: 5,
          }}
        >
          <Typography
            variant="h2"
            color="#0288d1"
            fontWeight="bold"
            gutterBottom
            textAlign="center"
            sx={{ fontSize: { xs: "2rem", sm: "2rem", md: "2.4rem" } }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            sx={{ mb: 4 }}
          >
            We’re here for any suggestions or simply to chat.
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  background: "linear-gradient(to right, #e0f7fa, #f7fbff)",
                  p: { xs: 3, md: 4 },
                  borderRadius: "12px",
                  boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0f4ff",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.03)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                  },
                  width: "100%",
                }}
              >
                <Typography
                  variant="h5"
                  component="h3"
                  color="text.primary"
                  gutterBottom
                  sx={{ fontSize: { xs: "20px" } }}
                >
                  Send us a Message
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
                  <StyledTextField
                    fullWidth
                    label="Name"
                    name="Name"
                    variant="standard"
                    margin="normal"
                    value={formData.Name}
                    onChange={handleChange}
                    error={!!errors.Name}
                    helperText={errors.Name}
                  />
                  <StyledTextField
                    fullWidth
                    label="Email"
                    name="Email"
                    variant="standard"
                    margin="normal"
                    value={formData.Email}
                    onChange={handleChange}
                    error={!!errors.Email}
                    helperText={errors.Email}
                    type="email"
                  />
                  <StyledTextField
                    fullWidth
                    label="Phone Number"
                    name="Phone"
                    variant="standard"
                    margin="normal"
                    value={formData.Phone}
                    onChange={handleChange}
                    error={!!errors.Phone}
                    helperText={errors.Phone}
                  />
                  <StyledTextField
                    fullWidth
                    label="Message"
                    name="Message"
                    variant="standard"
                    margin="normal"
                    value={formData.Message}
                    onChange={handleChange}
                    error={!!errors.Message}
                    helperText={errors.Message}
                    multiline
                    rows={4}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                      mt: 4,
                      bgcolor: isSubmitting ? "#b2ebf2" : "#6ac5fe",
                      transition: "background-color 0.3s, transform 0.3s",
                      "&:hover": {
                        bgcolor: "#3cdfff",
                        transform: "scale(1.05)",
                      },
                      textTransform: "none",
                      px: 4,
                      py: 1,
                      fontSize: "16px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Get in touch"
                    )}
                  </Button>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    sx={{ mt: 2, opacity: isSubmitting ? 0.6 : 1 }}
                  >
                    {submitMessage}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  p: { xs: 3, md: 4 },
                  borderRadius: "12px",
                  boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#f7faff",
                  border: "1px solid #e0f4ff",
                }}
              >
                <Stack spacing={3}>
                  {contactDetails.map((detail, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="flex-start"
                    >
                      <Box sx={{ color: "#000", fontSize: 28 }}>
                        {detail.icon}
                      </Box>
                      <Box>
                        <Typography fontWeight="medium" color="text.primary">
                          {detail.title}
                        </Typography>
                        {detail.content.map((line, i) => (
                          <Typography key={i} color="text.secondary">
                            {line}
                          </Typography>
                        ))}
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default Contactus;

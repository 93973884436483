import Servicesec1 from "../../components/Services/servicesec1";
import Servicesec2 from "../../components/Services/servicesec2";
import Contact from "../ContactUs/Contact";

const Services = () => {
    return(
        <> 
            <Servicesec1/>
            <Servicesec2/>
            <Contact/>
        </>
    )
}

export default Services;
import {React, useState, useEffect} from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Aboutsec1 from "../AboutUs/AboutSec1";
import Aboutsec2 from "../AboutUs/Aboutsec2";
import Servicesec1 from "../Services/servicesec1";
import Contact from "../../pages/ContactUs/Contact";
import mobile from "../../assets/images/mobile.png";
import Element2 from "../../assets/images/Element2.png";

const Homesec1 = () => {
  const navigate = useNavigate();
  const handleMoreClick = () => {
    navigate("/services"); 
  };
  const [clientSatisfaction, setClientSatisfaction] = useState(0);
  const [clientsServed, setClientsServed] = useState(0);
  // const animateCounter = (setter, targetValue, duration) => {
  //   let start = 0;
  //   const stepTime = Math.abs(Math.floor(duration / targetValue));

  //   const timer = setInterval(() => {
  //     start += 1;
  //     setter(start);

  //     if (start === targetValue) {
  //       clearInterval(timer);
  //     }
  //   }, stepTime);
  // };
  useEffect(() => {
    const totalDuration = 800;
  
    const satisfactionTarget = 100;
    const servicesTarget = 8;
  
    const satisfactionStepTime = Math.abs(Math.floor(totalDuration / satisfactionTarget));
    const servicesStepTime = Math.abs(Math.floor(totalDuration / servicesTarget));
  
    const animateCounter = (setter, targetValue, stepTime) => {
      let start = 0;
      const timer = setInterval(() => {
        start += 1;
        setter((prevValue) => {
          if (prevValue < targetValue) {
            return start; 
          } else {
            clearInterval(timer);
            return prevValue; 
          }
        });
  
        if (start === targetValue) {
          clearInterval(timer); 
        }
      }, stepTime);
    };
  
    animateCounter(setClientSatisfaction, satisfactionTarget, satisfactionStepTime);
    animateCounter(setClientsServed, servicesTarget, servicesStepTime);
  }, []);
  
  


  return (
    <div>
      <section id="hero">
        <Box
          sx={{
            width: "100%",
            height: { xs: "100vh", md: "100vh" },
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src={Element2}
            alt="Element Design"
            sx={{
              width: { xs: "50px", md: "150px" },
              height: "auto",
              position: "absolute",
              bottom: "10%",
              right: "0%",
              opacity: 1,
              zIndex: 0,
            }}
          />

<Box
  sx={{
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1,
    padding: { xs: "20px", sm: "80px" },
    marginBottom: {xs:"0px", sm:"0px"},
    marginLeft:{xs:"30px"},
    gap: { xs: "20px", md: "40px" },
    height: "100vh",
  }}
>
  {/* Text Content */}
  <Box
    sx={{
      width: { xs: "100%", md: "50%" },
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      justifyContent:{xs:"center", sm:"start"}
    }}
  >
    <Box
      sx={{
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: { xs: "16px", sm: "18px", md: "20px" },
        fontStyle: "italic",
        color: "#a2a2a2",
      }}
    >
      Tech for everyone
    </Box>

    <Box
      sx={{
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: { xs: "50px", sm: "80px", md: "150px" },
        color: "#407aff",
        lineHeight: "1",
        marginTop: "-10px",
      }}
    >
      Calebs.
    </Box>

    <Box
      sx={{
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: { xs: "16px", sm: "22px", md: "28px" },
        fontStyle: "italic",
        color: "#a2a2a2",
        marginTop: "-10px",
      }}
    >
      Innovation that Inspires, Solutions that Delight
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: "20px",
        marginTop: "40px",
      }}
    >
      {/* Stat Box 1 */}
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "#407aff",
          borderRadius: "15px",
          color: "white",
          textAlign: "center",
          width: "250px",
        }}
      >
        <Box sx={{ fontSize: { xs: "32px", sm: "48px", md: "64px" } }}>
          {clientSatisfaction}%
        </Box>
        <Box sx={{ fontSize: "18px", marginTop: "-5px" }}>
          Client Satisfaction
        </Box>
      </Box>

      {/* Stat Box 2 */}
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "#407aff",
          borderRadius: "15px",
          color: "white",
          textAlign: "center",
          width: "250px",
        }}
      >
        <Box sx={{ fontSize: { xs: "32px", sm: "48px", md: "64px" } }}>
          {clientsServed}
        </Box>
        <Box sx={{ fontSize: "18px", marginTop: "-5px" }}>
          Client Services
        </Box>
      </Box>
    </Box>
  </Box>

  {/* Image Content */}
  <Box
    sx={{
      width: { xs: "100%", sm: "100%" },
      height: { xs: "100%", sm: "400px", md: "500px" },
      backgroundImage: `url(${mobile})`,
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      transform: { xs: "none", md: "rotate(8deg)" },
      marginTop: { xs: "20px", sm: "0px" },
      transition: "transform 0.3s ease",
    }}
  />
</Box>

        </Box>
      </section>

      <section id="about">
        <Aboutsec1/>
        <Aboutsec2/>
      </section>

      <section id="services">
        <Servicesec1 />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", 
            alignItems: "center", 
            marginBottom: "50px",
            marginTop: '0px',
          }}
        >
          <Button
            variant="contained"
            sx={{
              padding: "12px 30px",
              backgroundColor: "#407aff",
              color: "white",
              fontSize: "18px",
              fontWeight: "bold",
              borderRadius: "50px",
              transition: "transform 0.3s ease, background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#305ec2",
                transform: "scale(1.05)",
              },
              boxShadow: "0px 8px 15px rgba(0, 64, 255, 0.3)",
              textTransform: "none",
            }}
            onClick={handleMoreClick}
          >
            Explore More Services
          </Button>
        </Box>
      </section>

      {/* <section id="portfolio">
        <Portfolio />
      </section> */}

      <section id="contact" backgroundColor="#fff">
        <Contact />
      </section>
    </div>
  );
};

export default Homesec1;

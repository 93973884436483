import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import webdv from "../../assets/images/webdevp.png";
import custom from "../../assets/images/custom.png";
import uiux from "../../assets/images/uiux.png";
import ml from "../../assets/images/ml.png";
import Element1 from "../../assets/images/Element2.png";
import Element3 from "../../assets/images/Element3.png";

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Custom hook to handle intersection observer
const useOnScreen = (ref, rootMargin = "0px") => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false); 
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setHasAnimated(true); 
        }
      },
      { rootMargin }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, rootMargin, hasAnimated]); 
  return isVisible;
};

const Servicesec1 = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const titleRef = useRef(null);
  const introTextRef = useRef(null);
  const serviceRef1 = useRef(null);
  const serviceRef2 = useRef(null);
  const serviceRef3 = useRef(null);
  const serviceRef4 = useRef(null);

  const titleVisible = useOnScreen(titleRef);
  const introTextVisible = useOnScreen(introTextRef);
  const service1Visible = useOnScreen(serviceRef1);
  const service2Visible = useOnScreen(serviceRef2);
  const service3Visible = useOnScreen(serviceRef3);
  const service4Visible = useOnScreen(serviceRef4);

  const servicesVisible = [
    service1Visible,
    service2Visible,
    service3Visible,
    service4Visible,
  ];

  const renderImage = (src, alt, index, animationDirection) => (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        height: isSmallScreen ? "250px" : "300px",
        width: isSmallScreen ? "250px" : "300px",
        borderRadius: "15px",
        animation: servicesVisible[index]
          ? `${animationDirection} 1s ease-out`
          : "none",
        transition: "transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        },
      }}
    />
  );

  return (
    <Box
      sx={{
        width: "100%",
        background: "#FFFFFF",
        position: "relative",
        textAlign: "center",
      }}
    >
      {/* Shaded blue areas */}
      {/* <Box
        sx={{
          position: "absolute",
          width: "300px",
          height: "300px",
          background:
            "radial-gradient(circle, rgba(25,118,210,0.5) 0%, rgba(255,255,255,0) 70%)",
          borderRadius: "50%",
          top: "40%",
          left: "0%",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "300px",
          height: "300px",
          background:
            "radial-gradient(circle, rgba(25,118,210,0.5) 0%, rgba(255,255,255,0) 70%)",
          borderRadius: "50%",
          top: "0%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
        }}
      /> */}

      <Box
        component="img"
        src={Element1}
        alt="Element 1"
        sx={{
          width: {xs:"50px", md:"150px"},
          height: "auto",
          position: "absolute",
          top: "10%",
          right: "0%",
          zIndex: 0, 
        }}
      />
      <Box
        component="img"
        src={Element3}
        alt="Element 3"
        sx={{
          width: { xs: "50px", md: "150px" },
          height: "auto",
          position: "absolute",
          left: "0%",
          bottom: "20%",
          zIndex: 0, 
        }}
      />


      <Typography
        variant="h2"
        color="#0288d1"
        fontWeight="bold"
        gutterBottom
        ref={titleRef}
        sx={{
          animation: titleVisible ? `${slideInFromTop} 1s ease-out` : "none",
          fontSize: { xs: "1.8rem", sm: "2rem", md: "2.4rem" }, 
          marginTop:"30px",
        }}
      >
        Our Services
      </Typography>
      {/* Introduction Text */}
      <Box
        ref={introTextRef}
        sx={{
          color: "#455a64",
          textAlign: "left",
          padding:"20px 8px",
          width:"80%",
          mx:"auto",
          zIndex: 1,
          animation: introTextVisible
            ? `${slideInFromTop} 1s ease-out`
            : "none",
        }}
      >
        <Typography
        sx={{
          fontSize: {xs:"14px", sm:"18px"},
          marginTop:"0px",
        }}>
       At Calebs, we strive to match you with the right solutions for your project. Whether you're a student needing help with assignments or a business requiring support on more advanced tasks, we’re focused on delivering dependable and accessible services tailored to your needs.
        </Typography>
      </Box>

      {/* Service Blocks */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop:"10px",
        }}
      >
        {[
          {
            title: "Web Development",
            desc: "We create websites that act as seamless bridges between your brand and your audience. With fully responsive designs, your site will look stunning and perform flawlessly across all devices.",
            img: webdv,
            ref: serviceRef1,
          },
          {
            title: "Custom Built Applications",
            desc: "Turn your ideas into reality with our custom-built apps. From your pocket to your desktop, we deliver mobile and web applications that bring the world to your fingertips, wherever you go.",
            img: custom,
            ref: serviceRef2,
          },
          {
            title: "UI/UX and Graphics Design",
            desc: "Every click should feel like an adventure. Our design team crafts intuitive and engaging user interfaces, alongside striking visuals, to ensure every user’s journey is a memorable experience.",
            img: uiux,
            ref: serviceRef3,
          },
          {
            title: "Machine Learning",
            desc: "Harness the power of machine learning to see the road ahead. Our intelligent systems turn data into foresight, enabling you to make smarter decisions and automate complex processes.",
            img: ml,
            ref: serviceRef4,
          },
        ].map((service, index) => (
          <Box
            key={index}
            ref={service.ref}
            sx={{
              width: "80%",
              backgroundColor: "#ffffff",
              display: "flex",
              flexDirection: isSmallScreen
                ? "column"
                : index % 2 === 0
                ? "row"
                : "row-reverse", // Alternate card direction
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
              padding: isSmallScreen ? "15px" : "40px",
              marginBottom: "30px",
              borderRadius: "25px",
              boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
              transition:
                "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.02)",
                boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            {/* Image Slide-In Effect */}
            {renderImage(
              service.img,
              service.title,
              index,
              index % 2 === 0 ? slideInFromLeft : slideInFromRight
            )}

            {/* Text with Slide-In from Top Effect */}
            <Box
              sx={{
                paddingLeft: isSmallScreen
                  ? "0"
                  : index % 2 === 0
                  ? "30px"
                  : "0",
                paddingRight: isSmallScreen
                  ? "0"
                  : index % 2 === 0
                  ? "0"
                  : "30px",
                textAlign: "justify",
                color: "#455a64",
                zIndex: 1,
                animation: servicesVisible[index]
                  ? `${slideInFromTop} 1s ease-out`
                  : "none",
              }}
            >
              <Typography variant="h1" color="#0288d1" fontWeight="bold" sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "2.4rem" }, marginTop:"10px"}}>
                {service.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs:"15px", md:"19px"},
                  color: "#546e7a",
                  marginTop: "10px",
                  fontStyle: "italic",
                }}
              >
                {service.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Servicesec1;
